/*------------------------------------------------------------*\
    Navbar
\*------------------------------------------------------------*/

@media (max-width: $screen-sm) {
	.fs-background {
		overflow: visible !important;
	}
}
.navbar-collapse {
	border: 0;
	-webkit-overflow-scrolling: auto; //Iphone fix
}
.navbar-default {
	margin-bottom: 0;
	background: #fff;
	font-size: 16px;
	font-family: $font-family-sans-serif;
	font-weight: 500;
	@include border-radius(0);

	.container {
		position: relative;
	}
	.navbar-header {
		position: relative;

		.navbar-cart {
			display: none;
			padding: 9px 15px;
			font-size: 20px;
			float: right;

			@media (max-width: $screen-sm) {
				display: block;
			}
		}
	}
	.navbar-brand {
		height: auto;
		padding: 5px 15px;
		display: none;

		img {
			max-width: 180px;
		}
		@media (max-width: $grid-float-breakpoint-max) {
			display: inline-block;
		}
	}
	.navbar-toggle {
		border: none;
		float: left;
		margin-top: 12px;
		margin-left: 10px;
		margin-bottom: 10px;
		padding-right: 48px;
		background: $brand-secondary;

		&:hover, &:focus {
			background: transparent;
		}
		.icon-bar {
			background: #000;
		}
		.title {
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 10px;
			text-transform: uppercase;
		}
		@media (max-width: $screen-sm) {
			float: right;
		}
	}
	.navbar-nav {
		> li {
			> a {
				padding: 15px 15px;
				color: $gray-dark;
				font-weight: 300;

				&:hover, &:focus {
					background: #fff;
				}
			}
		}
		> .menu-bar {
			display: none;

			@media (max-width: $screen-sm) {
				display: block;
			}
		}
		> .active {
			> a {
				background: transparent;
				font-weight: 700;

				&:hover, &:active {
					background: #fff;
				}
			}
		}
		> .dropdown {
			position: relative;

			@media (min-width: $screen-sm) {
				&:hover, &:focus {
					.dropdown-menu {
						display: block;
						min-width: 240px;
						border-bottom: 4px solid $brand-secondary;
					}
				}
				.toggle-drop {
					display: none;
				}
			}
			@media (max-width: $screen-sm) {
				.toggle-drop {
					cursor: pointer;
					position: absolute;
					top: 6px;
					right: 2px;
					display: block;

					&:before {
						padding: 8px 16px;
						background: $brand-primary;
						color: #fff;
						content: '+';
					}
				}
				&.open {
					.toggle-drop {
						&:before {
							content: '-';
						}
					}
				}
			}
		}
		.dropdown-menu {
			border: 0;
			@include box-shadow(0 5px 15px rgba(0,0,0,0.1));
			
			> li {
				> a {
					font-size: 16px;
				}
				.yamm-content {
					.title {
						font-size: 18px;
					}
					ul {
						list-style: none;
						padding: 0;

						li {
							&:before {
								margin-right: 5px;
								content: "\f105";
								font-family: 'FontAwesome';
								color: $brand-primary;
							}
						}
					}
				}
			}
			@media (max-width: $screen-sm) {
				padding-left: 15px;

				> li {
					.yamm-content {
						ul {
							li {
								&.title {
									font-size: 16px;
								}
								a {
									font-weight: 400;
								}
							}
						}
					}
				}
			}
		}
	}
}


/*------------------------------------------------------------*\
    YAMM
\*------------------------------------------------------------*/

.yamm {
	@media (min-width: $screen-sm) {
		// reset positions 
		.nav, .collapse, .dropup, .dropdown {
			position: static !important;
		}  

		// propagate menu position under container for fw navbars
		.container {
			position: relative;
		}

		// by default aligns menu to left
		.dropdown-menu {
			left: auto;
			top: auto;
		}
		
		// Content with padding 
		.yamm-content {
			padding: 30px 30px;
		}

		// Fullwidth menu
		.dropdown.yamm-fw .dropdown-menu {
			left: 0; right: 0;
		}
	}
}
