/*------------------------------------------------------------*\
    VARIABLES
\*------------------------------------------------------------*/

$brand-primary:         #005c91;
$brand-secondary: 		#78c7eb;
$brand-cta:             #e10808;

$gray-base:              #000;
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   lighten($gray-base, 33.5%); // #555
$gray-light:             lighten($gray-base, 46.7%); // #777
$gray-lighter:           lighten($gray-base, 93.5%); // #eee

$font-size-base:          16px;

$font-size-h1:            floor(($font-size-base * 2)); // ~36px
$font-size-h2:            floor(($font-size-base * 1.6)); // ~30px
$font-size-h3:            ceil(($font-size-base * 1.3)); // ~24px
$font-size-h4:            ceil(($font-size-base * 1.1)); // ~18px
$font-size-h5:            $font-size-base;
$font-size-h6:            ceil(($font-size-base * 0.85)); // ~12px

$text-color: $gray-dark;


$font-family-sans-serif:  'Open Sans', sans-serif;
//$font-family-serif:       'Comfortaa', cursive;


$screen-xs:                  480px;
$screen-sm: 			  	 768px;
$screen-md:                  992px;
$screen-lg:                  1200px;

$screen-xs:                  480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px;
$screen-md-min:              $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);
