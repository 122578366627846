/*------------------------------------------------------------*\
    Page
\*------------------------------------------------------------*/

.page-title {
	margin-top: 60px;
	position: relative;
	padding: 120px 0 0px;

	.container {
		position: relative;
		z-index: 10;
	}
	svg {
		position: absolute;
		bottom: -34px;
		z-index: 1;
	}
	@media (max-width: $screen-sm) {
		margin-top: 30px;
		padding: 40px 0 0px;

		svg {
			bottom: -10px;
		}
	}
}
.content {
	background: #fff;
	padding: 80px 0px;

	h1 {
		margin-top: 0;
	}
	.sideblock {
		margin-top: 60px;
	}
	.contact {
		padding: 15px;
		background: $gray-lighter;
	}
}

.banner {
	overflow: hidden;

	.svg-top, .svg-bottom {
		position: absolute;
		left: 0;
		right: 0;
		z-index: 100;
	}
	.svg-top {
		top: 0;
	}
	.svg-bottom {
		bottom: 0;
	}
}

.blocks {
	.block {
		display: block;
		margin-bottom: 30px;
		padding: 15px 15px;
		text-decoration: none;
		@include box-shadow(0px 0px 6px 0px rgba(0,0,0,0.05));
		@include transition(all, 300ms, ease-in-out);
		
		&:hover, &:focus {
			@include box-shadow(0px 0px 8px 0px rgba(0,0,0,0.15));
			@include transition(all, 300ms, ease-in-out);
		}
		.btn {
			margin-top: 10px;
		}
	}
}

.two-blocks {
	margin-bottom: 60px;

	.content {
		padding: 40px;
	}
}

.action-bar {
	position: relative;
	padding: 120px 0;
	background: #fff;
	text-align: center;
	overflow: hidden;

	.container {
		position: relative;
		z-index: 1100;
	}
	&.is-primary, &.is-secondary, &.is-third {
		color: #fff;
	}
	&.is-primary {
		background: $brand-primary;
	}
	&.is-secondary {
		background: $brand-secondary;
	}
	&.is-third {
		background: $brand-cta;
	}
	.title {
		margin-bottom: 30px;
		text-transform: uppercase;
		font-size: $font-size-h1;
		font-weight: 700;
	}
	@media (max-width: $screen-sm) {
		.title {
			font-size: 26px;
		}
	}
	.svg-top, .svg-bottom {
		position: absolute;
		left: 0;
		right: 0;
		z-index: 100;
	}
	.svg-top {
		top: 0;
	}
	.svg-bottom {
		bottom: 0;
	}
}
