/*------------------------------------------------------------*\
    Header
\*------------------------------------------------------------*/

header {
	padding: 15px 0;
	font-size: 14px;

	.language {
		margin-top: 40px;
		float: right;
		margin-bottom: 0;
		padding: 0;
		list-style: none;

		li {
			display: inline-block;

			a {
				color: $gray-light;
			}
			+ li {
				&:before {
					content: '| ';
					color: $gray-lighter;
				}
			}
		}
	}
	@media (max-width: $grid-float-breakpoint-max) {
		display: none;
	}
}
