/*------------------------------------------------------------*\
    Forms
\*------------------------------------------------------------*/

.form-control {
    box-shadow: none;
    -webkit-box-shadow: none;
    border: none;
    @include box-shadow(0px 2px 5px 0px rgba(0,0,0,0.08));
    @include border-radius(9px);

    &:hover, &:focus {
        box-shadow: none;
        -webkit-box-shadow: none;
        @include box-shadow(0px 2px 5px 0px rgba(0,0,0,0.15));
    }
}
