/*------------------------------------------------------------*\
    Buttons
\*------------------------------------------------------------*/

.btn {
	font-size: 16px;
	@include transition(all, 300ms, ease-in-out);

	&:hover, &:focus {
		@include transition(all, 300ms, ease-in-out);
	}
}
.btn-primary {
	background: $brand-primary;

	&:hover, &:focus {
		background: darken($brand-primary, 10);
	}
}
.btn-action {
	background: $brand-cta;
	color: #fff;
	font-size: 16px;

	&:hover, &:focus {
		background: darken($brand-cta, 10);
		color: #fff;
	}
}
