/*------------------------------------------------------------*\
    Breadcrumbs
\*------------------------------------------------------------*/

.breadcrumb {
    padding: 0;
    background: transparent;

    @media (max-width: $screen-sm) {
        font-size: 12px;
    }
}
