/*------------------------------------------------------------*\
    Home
\*------------------------------------------------------------*/

.hero {
	position: relative;
	min-height: 480px;
	background: #efefef;

	.hero-content {
		position: absolute;
		top: 50%;
		left: 60%;
		transform: translate(-50%, -50%);
		padding: 20px 30px;
		background: rgba(0, 0, 0, 0.10);
		color: #fff;
		z-index: 1000;
		width: 100%;
		max-width: 680px;

		.title {
			font-size: $font-size-h1 * 1.15;
			font-weight: 600;
		}
		.info {
			font-size: 18px;
		}
		.btn {
			padding: 8px 16px;
			font-size: 18px;
		}
		@media (max-width: $screen-sm-min) {
			left: 50%;

			.title {
				font-size: $font-size-h2;
			}
			.info {
				font-size: 16px;
			}
			.btn {
				padding: 8px 16px;
				font-size: 16px;
			}
		}
		@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
			left: 50%;
		}
		@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
			left: 50%;
		}
	}
	.svg-top, .svg-bottom {
		position: absolute;
		z-index: 100;
	}
	.svg-top {
		top: 0;
	}
	.svg-bottom {
		bottom: 0;
	}
	@media (min-width: $grid-float-breakpoint) {	
		&.zoom {
			.fs-background-media {
				animation : 60s linear 1s normal none infinite zoominout;
			}
		}
	}
	@media (max-width: $screen-sm) {
		min-height: 180px;
	}
}
