/*------------------------------------------------------------*\
    Global
\*------------------------------------------------------------*/
html {
	height: 100%;
}
body {
	position: relative;
	font-size: 16px;
	line-height: 1.746;
	font-family: $font-family-sans-serif;
	min-height: 100%;
	border-top: 2px solid $brand-secondary;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 700;
}
h1 {
	font-size: $font-size-h1;
}
h2 {
	font-size: $font-size-h2;
}
h3 {
	font-size: $font-size-h3;
}
h4 {
	font-size: $font-size-h4;
}
h5 {
	font-size: $font-size-h5;
}
h6 {
	font-size: $font-size-h6;
}
.lead {
	font-size: 20px;
	font-weight: 400;
}

@media (max-width: $screen-sm) {
	h1 {
		font-size: ceil($font-size-h1 * 0.8);
	}
	h2 {
		font-size: ceil($font-size-h2 * 0.8);
	}
	.lead {
		font-size: 17px;
	}
}

img {
	max-width: 100%;
	height: auto;
}

a, .btn {
	outline: none;

	&:hover, &:focus, &:active {
		outline: none;
	}
}

a {
	color: $text-color;

	&:hover, &:focus {
		color: $text-color;
	}
}
